<template>
  <div>
    <!-- <Favorites/>
    <UpComming/>
    <TopTen/>
    <Suggested/>
    <Detail/>
    <Trending/>
    <Thrillers/> -->
    <!-- <LatestEpisode/> -->
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'
// import LatestEpisode from './ShowDetailPage/LatestEpisode'
// import Favorites from './Components/Favorites/Favorite'
// import Detail from './Components/Details/Detail'
// import Suggested from './Components/Suggested/Suggestion'
// import TopTen from './Components/TopTen/Top'
// import Trending from './Components/Trending/Trending'
// import Thrillers from './Components/TvThrillers/Thrillers'
// import UpComming from './Components/UpcomingMovie/Upcomming'
export default {
  name: 'MainPage',
  components: {
    // Favorites,
    // Detail,
    // Suggested,
    // TopTen,
    // Trending,
    // Thrillers,
    // UpComming
    // LatestEpisode
  },
  mounted () {
    core.index()
  },
  created () {
    this.$router.replace('serial')
  }

}
</script>
